




































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss"
    })
  },
  methods: {
    title(title: string): string {
      if (this.isCN && !this.isMobile) {
        return title.replace("<br />", "，");
      } else {
        return title;
      }
    }
  }
});
